import { WorkDetailsModel } from "../../../../factories/work/model/work-details.model";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import { repeats, repeatsOn } from "../../../../types/types";
import { DatePicker } from "baseui/datepicker";
import { DATE_FORMATS } from "../../../../app/commonOps/CommonDateOps";
import { transformDateToUTC } from "../../../../utils/transformDate";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import Button from "../../../../components/button/Button";
import { useStopRecurringMutation } from "../../../../slices/WorkSlice";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import ConfirmModal from "../../../../components/modal/ConfirmModal";

enum RepeatName {
  Monthly = "Monthly",
  Yearly = "Yearly",
  Weekly = "Weekly",
  Never = "Never",
}

interface IValues {
  repeatOnId: string | null;
  repeat: string;
  repeatCount: string;
  days: string;
  repeatUntil: Date;
  generatedDate: string | null;
  dueDate: string;
  beforeStartDate: number;
  beforeStartDateValue: string;
}

interface IRepeatProps {
  close: () => void;
  onSubmit: (data: IValues) => void;
  values: IValues;
  onChange: (values: IValues) => void;
  jobId: string;
  work: WorkDetailsModel;
  setIsOpenMessage: Dispatch<SetStateAction<boolean>>;
}

enum RepeatDueDate {
  LastDayOfMonth = "Last day of the month",
  One = 1,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty,
  TwentyOne,
  TwentyTwo,
  TwentyThree,
  TwentyFour,
  TwentyFive,
  TwentySix,
  TwentySeven,
  TwentyEight,
  TwentyNine,
  Thirty,
  ThirtyOne,
}

enum RepeatDueDateMonth {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

const repeatDueDateMonthArray: RepeatDueDateMonth[] = [
  RepeatDueDateMonth.January,
  RepeatDueDateMonth.February,
  RepeatDueDateMonth.March,
  RepeatDueDateMonth.April,
  RepeatDueDateMonth.May,
  RepeatDueDateMonth.June,
  RepeatDueDateMonth.July,
  RepeatDueDateMonth.August,
  RepeatDueDateMonth.September,
  RepeatDueDateMonth.October,
  RepeatDueDateMonth.November,
  RepeatDueDateMonth.December,
];

enum RepeatDueDateDay {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

const repeatDueDateDayArray: RepeatDueDateDay[] = [
  RepeatDueDateDay.Monday,
  RepeatDueDateDay.Tuesday,
  RepeatDueDateDay.Wednesday,
  RepeatDueDateDay.Thursday,
  RepeatDueDateDay.Friday,
  RepeatDueDateDay.Saturday,
  RepeatDueDateDay.Sunday,
];

const repeatDueDateArray: RepeatDueDate[] = [
  RepeatDueDate.LastDayOfMonth,
  RepeatDueDate.One,
  RepeatDueDate.Two,
  RepeatDueDate.Three,
  RepeatDueDate.Four,
  RepeatDueDate.Five,
  RepeatDueDate.Six,
  RepeatDueDate.Seven,
  RepeatDueDate.Eight,
  RepeatDueDate.Nine,
  RepeatDueDate.Ten,
  RepeatDueDate.Eleven,
  RepeatDueDate.Twelve,
  RepeatDueDate.Thirteen,
  RepeatDueDate.Fourteen,
  RepeatDueDate.Fifteen,
  RepeatDueDate.Sixteen,
  RepeatDueDate.Seventeen,
  RepeatDueDate.Eighteen,
  RepeatDueDate.Nineteen,
  RepeatDueDate.Twenty,
  RepeatDueDate.TwentyOne,
  RepeatDueDate.TwentyTwo,
  RepeatDueDate.TwentyThree,
  RepeatDueDate.TwentyFour,
  RepeatDueDate.TwentyFive,
  RepeatDueDate.TwentySix,
  RepeatDueDate.TwentySeven,
  RepeatDueDate.TwentyEight,
  RepeatDueDate.TwentyNine,
  RepeatDueDate.Thirty,
  RepeatDueDate.ThirtyOne,
];
export const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};
export const Repeat: React.FC<IRepeatProps> = ({
  onChange,
  values,
  close,
  onSubmit,
  work,
  setIsOpenMessage,
}) => {
  const onCompletionValue =
    "4093E0B1-452F-458F-8BFC-E8956B92D95F".toLowerCase();
  const onSpecificValue = "E6ACA4E9-E91B-4314-98F4-254CF05EFFCF".toLowerCase();
  const onImmediatelyValue =
    "DD6B35FD-01FF-48CF-9F58-407727DE6930".toLowerCase();

  const [data, setData] = useState<IValues>({
    ...values,
    repeatCount: `${work.repeatValue}` || "1",
  });
  const [repeatName, setRepeatName] = useState<string | undefined>("");
  const [repeatsMonth, setRepeatsMonth] = useState<RepeatDueDateMonth>(
    RepeatDueDateMonth[moment().format("MMMM") as RepeatDueDateMonth],
  );
  const [repeatsDate, setRepeatsDate] = useState(moment().date());
  const [repeatsDueDateMonth, setRepeatsDueDateMonth] = useState<RepeatDueDate>(
    RepeatDueDate.LastDayOfMonth,
  );
  const [repeatOnValue, setRepeatOnValue] = useState(
    work?.repeatOnId || onCompletionValue,
  );

  const [repeatsDueDateDayOfWeek, setRepeatsDueDateDayOfWeek] =
    useState<RepeatDueDateDay>(
      RepeatDueDateDay[moment().format("dddd") as RepeatDueDateDay],
    );
  const [value, setValue] = useState(work.beforeStartDate);
  const [counts, setCounts] = useState("");
  const [isMonth, setIsMonth] = useState(work.beforeStartDateValue);
  const min = 1;
  const max = isMonth === "Months" ? 12 : 365;

  const debouncedSetCounts = useCallback(
    debounce((newValue: string) => setCounts(newValue), 300),
    [],
  );
  const [stopRecurring] = useStopRecurringMutation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    if (numericValue === "") {
      setValue(0);
      debouncedSetCounts("");
      return;
    }
    const safeValue = parseInt(numericValue, 10) < 1 ? "1" : numericValue;
    const numericSafeValue = parseInt(safeValue, 10);

    if (numericSafeValue >= min && numericSafeValue <= max) {
      setValue(Number(safeValue));
      debouncedSetCounts(safeValue);
    }
  };
  useEffect(() => {
    if (
      !repeats.find(
        (item) => item?.id?.toLowerCase() === data?.repeat?.toLowerCase(),
      )?.name
    ) {
      onChangeHandler("repeat", repeats[0].id.toLowerCase());
    }
  }, [repeatName]);

  const repeatsDueDateArray = useMemo(() => {
    {
      if (repeatsMonth === RepeatDueDateMonth.February) {
        return [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28,
        ];
      }
      if (
        repeatsMonth === RepeatDueDateMonth.January ||
        repeatsMonth === RepeatDueDateMonth.March ||
        repeatsMonth === RepeatDueDateMonth.May ||
        repeatsMonth === RepeatDueDateMonth.July ||
        repeatsMonth === RepeatDueDateMonth.August ||
        repeatsMonth === RepeatDueDateMonth.October ||
        repeatsMonth === RepeatDueDateMonth.December
      ) {
        return [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ];
      }
      return [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ];
    }
  }, [repeatsMonth]);

  const findRepeatName = () => {
    switch (repeatName) {
      case RepeatName.Monthly:
        return "Month";
      case RepeatName.Yearly:
        return "Year";
      case RepeatName.Weekly:
        return "Week";
      case RepeatName.Never:
        return "Never";
      default:
        return "";
    }
  };

  const onChangeHandler = (
    name:
      | "repeat"
      | "repeatCount"
      | "days"
      | "repeatsUtil"
      | "generatedDate"
      | "dueDate",
    value: string | Date,
  ) => {
    switch (name) {
      case "repeat": {
        setData((prev) => ({ ...prev, repeat: value as string }));
        break;
      }
      case "repeatCount": {
        setData((prev) => ({ ...prev, repeatCount: value as string }));
        break;
      }
      case "days": {
        setData((prev) => ({ ...prev, days: value as string }));
        break;
      }
      case "repeatsUtil": {
        setData((prev) => ({ ...prev, repeatUntil: value as Date }));
        break;
      }
      case "dueDate": {
        setData((prev) => ({
          ...prev,
          dueDate: moment(value).toISOString(),
        }));
        break;
      }
      default: {
        break;
      }
    }
  };

  useLayoutEffect(() => {
    setRepeatName(
      repeats.find(
        (item) => item?.id?.toLowerCase() === data?.repeat?.toLowerCase(),
      )?.name,
    );
  }, [data?.repeatOnId, data?.repeat]);

  const checkMaxDayOfMonth = (
    month: string | number,
    date: number,
    dateString: string,
    year: number,
  ) => {
    if (month === RepeatDueDateMonth.February || month === 2) {
      if (year % 4 === 0) {
        return date > 29 ? 29 : dateString;
      } else {
        return date > 28 ? 28 : dateString;
      }
    }
    if (
      month === RepeatDueDateMonth.April ||
      month === RepeatDueDateMonth.June ||
      month === RepeatDueDateMonth.September ||
      month === RepeatDueDateMonth.November ||
      month === 4 ||
      month === 6 ||
      month === 9 ||
      month === 11
    ) {
      return date > 30 ? 30 : dateString;
    } else return dateString;
  };

  const generateDate = () => {
    if (data.repeat === repeats[0].id) {
      if (repeatsDueDateMonth === RepeatDueDate.LastDayOfMonth) {
        return moment(work?.dueDate)
          .add(data.repeatCount, "month")
          .endOf("month")
          .startOf("D")
          .toDate();
      } else {
        const year = moment(work?.dueDate).year();
        const date =
          repeatsDueDateMonth < 10
            ? `0${repeatsDueDateMonth}`
            : repeatsDueDateMonth.toString();
        const newMonth = moment(work?.dueDate).month();

        return moment(
          `${checkMaxDayOfMonth(
            newMonth > 10 ? 1 : newMonth + 2,
            Number(date),
            date,
            newMonth > 10 ? year + 1 : year,
          )}/${newMonth > 10 ? 1 : newMonth + 2}/${
            newMonth > 10 ? year + 1 : year
          }`,
          "DD/MM/YYYY",
        ).toDate();
      }
    }
    if (data.repeat === repeats[1].id) {
      const nextYear = moment(work?.dueDate)
        .add(1, "y")
        .format("yyyy")
        .toString();

      const currentYear = moment(work?.dueDate).format("yyyy").toString();
      const date = repeatsDate < 10 ? `0${repeatsDate}` : repeatsDate;
      const dueDateDate = moment(work?.dueDate).unix();
      const selectedDate = moment(
        `${date}/${repeatsMonth}/${currentYear}`,
      ).unix();
      const year = dueDateDate < selectedDate ? currentYear : nextYear;
      return moment(`${date}/${repeatsMonth}/${year}`, "DD/MMMM/YYYY")
        .startOf("D")
        .toDate();
    }
    if (data.repeat === repeats[2].id) {
      return moment()
        .clone()
        .day(repeatsDueDateDayOfWeek)
        .add(Number(data.repeatCount) * 7, "d")
        .startOf("day")
        .toDate();
    } else return "";
  };

  const onSave = () => {
    const generatedDate = generateDate();
    onChange({
      ...data,
      dueDate: moment(generatedDate).toISOString(),
      beforeStartDate: Number(counts),
      beforeStartDateValue: isMonth,
    });
    onSubmit({
      ...data,
      dueDate: moment(generatedDate).toISOString(),
      beforeStartDate: Number(counts),
      beforeStartDateValue: isMonth || "Days",
      repeatOnId: repeatOnValue,
    });
    if (data.repeat === repeats[3].id) {
      stopRecurring({
        jobId: work?.id,
        orgId: getLinkedFirm()?.orgId || "",
      });
    }
  };

  const renderDueDate = () => {
    //Month
    if (data.repeat === repeats[0].id) {
      return (
        <div className={"flex items-center gap-4"}>
          <div className={"text-base text-gray-400"}>Due date is</div>
          <div className={"max-w-fit"}>
            <select
              className={"select select-bordered w-fit"}
              value={repeatsDueDateMonth}
              onChange={(e) =>
                setRepeatsDueDateMonth(e.target.value as RepeatDueDate)
              }>
              {repeatDueDateArray.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className={"text-base text-gray-400"}>Day of the month</div>
        </div>
      );
    } //Year
    if (data.repeat === repeats[1].id) {
      return (
        <div className={"flex items-center gap-4"}>
          <div className={"text-base text-gray-400"}>Due date is</div>
          <div className={"max-w-fit"}>
            <select
              className={"select select-bordered w-fit"}
              onChange={(e) => setRepeatsDate(Number(e.target.value))}
              value={repeatsDate}>
              {repeatsDueDateArray.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className={"text-base text-gray-400"}>Day of</div>
          <div className={"max-w-fit"}>
            <select
              className={"select select-bordered w-fit"}
              value={repeatsMonth}
              onChange={(e) => {
                setRepeatsMonth(
                  RepeatDueDateMonth[e?.target?.value as RepeatDueDateMonth],
                );
                if (e.target.value === RepeatDueDateMonth.February) {
                  setRepeatsDate(repeatsDate > 28 ? 28 : repeatsDate);
                }
                if (
                  e.target.value === RepeatDueDateMonth.April ||
                  e.target.value === RepeatDueDateMonth.June ||
                  e.target.value === RepeatDueDateMonth.September ||
                  e.target.value === RepeatDueDateMonth.November
                ) {
                  setRepeatsDate(repeatsDate > 30 ? 30 : repeatsDate);
                }
              }}>
              {repeatDueDateMonthArray.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    } //Week
    if (data.repeat === repeats[2].id) {
      return (
        <div className={"flex items-center gap-4"}>
          <div className={"text-base text-gray-400"}>Due date is</div>
          <div className={"max-w-fit"}>
            <select
              className={"select select-bordered w-fit"}
              value={repeatsDueDateDayOfWeek}
              onChange={(e) =>
                setRepeatsDueDateDayOfWeek(e.target.value as RepeatDueDateDay)
              }>
              {repeatDueDateDayArray.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      onClick={close}
      className={
        "fixed left-0 top-0 z-[101] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-25"
      }>
      <div
        id={"repeats"}
        className={"min-w-[500px] rounded-2xl bg-white p-6"}
        onClick={(event) => event.stopPropagation()}>
        <div>
          {/*{dueDate && (
            <div className={"mb-2 text-sm text-gray-600"}>
              Current due date for next work:{" "}
              <span className={"font-semibold"}>
                {moment(dueDate).format("DD MMM, YYYY")}
              </span>
            </div>
          )}*/}
          {generateDate() && (
            <div className={"mb-4 text-sm text-gray-600"}>
              New due date for next work:{" "}
              <span className={"font-semibold"}>
                {moment(generateDate()).format("DD MMM, YYYY")}
              </span>
            </div>
          )}
          <div></div>
          <div className={"mb-4 flex items-center gap-4"}>
            <div className={"item-center flex gap-4"}>
              <div className={"flex items-center text-base text-gray-400"}>
                Repeats
              </div>
              <div>
                <select
                  onChange={(event) =>
                    onChangeHandler("repeat", event.target.value)
                  }
                  value={data.repeat}
                  className={"select select-bordered w-full"}>
                  {repeats.map((r) => (
                    <option
                      value={r.id.toLowerCase()}
                      key={r.id}
                      selected={
                        repeatName
                          ? data.repeat === r.id.toLowerCase()
                          : repeats[0].id.toLowerCase() === r.id.toLowerCase()
                      }>
                      {r?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {generateDate() &&
              data.repeat !==
                repeats.find((item) => item.name === "Yearly")?.id && (
                <div className={"flex items-center gap-4"}>
                  <div className={"text-base text-gray-400"}>Repeats every</div>
                  <div className={"max-w-[50px]"}>
                    <input
                      className={
                        "input input-bordered max-w-full p-0 text-center"
                      }
                      onChange={(event) => {
                        const value = event.target.value;
                        const numericValue = value.replace(/[^0-9]/g, "");
                        const safeValue =
                          numericValue === "0" || parseInt(numericValue, 10) < 1
                            ? "1"
                            : numericValue;

                        onChangeHandler("repeatCount", safeValue);
                      }}
                      maxLength={2}
                      value={data?.repeatCount}
                    />
                  </div>
                  <div className={"text-base text-gray-400"}>
                    {findRepeatName()}
                  </div>
                </div>
              )}
          </div>
          {generateDate() && (
            <div>
              <div className={"mb-4"}>{renderDueDate()}</div>
              <div className={"mb-4 flex items-center gap-4"}>
                <div className={"text-base text-gray-400"}>Start date is</div>
                <div className={"max-w-[50px]"}>
                  <input
                    className={
                      "input input-bordered max-w-full p-0 text-center"
                    }
                    onChange={(event) => {
                      const value = event.target.value;
                      const numericValue = value.replace(/[^0-9]/g, "");
                      onChangeHandler("days", numericValue);
                    }}
                    maxLength={2}
                    value={data?.days}
                  />
                </div>
                <div className={"text-base text-gray-400"}>
                  Day(s) before the due date
                </div>
              </div>
              <div className={"mb-4 flex items-center gap-4"}>
                <div className={"text-base text-gray-400"}>Repeat until</div>
                <div className={"w-fit"}>
                  <DatePicker
                    overrides={{
                      MonthYearSelectPopover: {
                        props: {
                          overrides: {
                            Body: {
                              style: { zIndex: 10000 },
                            },
                          },
                        },
                      },
                      Input: {
                        props: {
                          mountNode: document.getElementById("repeats"),
                          overrides: {
                            Input: {
                              style: () => ({
                                backgroundColor: "#FFFFFF",
                              }),
                            },
                            Root: {
                              style: () => ({
                                borderTopWidth: "1px",
                                borderRightWidth: "1px",
                                borderBottomWidth: "1px",
                                borderLeftWidth: "1px",
                                borderTopColor: "#D4D6D9",
                                borderRightColor: "#D4D6D9",
                                borderBottomColor: "#D4D6D9",
                                borderLeftColor: "#D4D6D9",
                              }),
                            },
                          },
                        },
                      },
                      Popover: {
                        props: {
                          overrides: {
                            Body: {
                              style: {
                                zIndex: 9999,
                              },
                            },
                          },
                        },
                      },
                    }}
                    value={data.repeatUntil}
                    formatString={DATE_FORMATS.dateOnly}
                    onChange={({ date }) => {
                      if (date instanceof Date) {
                        onChangeHandler(
                          "repeatsUtil",
                          transformDateToUTC(date),
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className={"mb-4 grid grid-rows-2 items-center gap-4"}>
                <div className={"flex items-center gap-4"}>
                  <div className={"text-base text-gray-400"}>Create work</div>
                  <div className={"w-[200px]"}>
                    <select
                      onChange={(event) => {
                        setRepeatOnValue(event.target.value);
                      }}
                      value={
                        repeatOnValue || onCompletionValue || onImmediatelyValue
                      }
                      className={"select select-bordered w-full"}>
                      <option value={onCompletionValue}>On completion</option>
                      <option value={onSpecificValue}>On specific date</option>
                      <option value={onImmediatelyValue}>Immediately</option>
                    </select>
                  </div>
                </div>

                {repeatOnValue === onSpecificValue && (
                  <div className={"flex items-center gap-4"}>
                    <div className={"w-[100px]"}></div>
                    <div className={"flex items-center gap-[10px]"}>
                      <div>
                        <input
                          className={
                            "input input-bordered w-[50px] p-0 text-center"
                          }
                          onChange={handleChange}
                          maxLength={3}
                          value={value}
                        />
                      </div>
                      <div>
                        <select
                          onChange={(event) =>
                            event.target.value === "Month(s)"
                              ? setIsMonth("Months")
                              : setIsMonth("Days")
                          }
                          value={isMonth === "Months" ? "Month(s)" : "Day(s)"}
                          className={"select select-bordered w-full"}>
                          <option>Day(s)</option>
                          <option>Month(s)</option>
                          ))
                        </select>
                      </div>
                    </div>
                    <div className={"text-base text-gray-400"}>
                      before start date
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={"flex items-center justify-between gap-2"}>
          {/*<Button
            buttonType={"button"}
            colorType={"error"}
            onClick={deleteRepeat}
            label={"Delete"}
            extraClasses={"normal-case"}
          />*/}
          <div className={"flex items-center gap-2"}>
            <OutlineButton
              colorType={"neutral"}
              label={"Cancel"}
              onClick={() => {
                setData({ ...values, repeatCount: "1" });
                onChange({
                  ...values,
                  repeatOnId: repeatsOn[0].id.toLowerCase(),
                });
                close();
              }}
              extraClasses={"normal-case"}
            />
            <Button
              buttonType={"button"}
              onClick={() => {
                setIsOpenMessage(true);
                onSave();
              }}
              label={"Save"}
              extraClasses={"normal-case"}
              disabled={!(data.repeatCount && +data.repeatCount >= 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
